import React from "react";

export function LoaderComman({ isLoading, children }: { isLoading: boolean, children: React.ReactNode; }) {
  return (
    <>
      {isLoading && (
        <>
          <div className='overlay'></div>
          <div className='bgImgspanner show'>
            <div className='bgImgCenter'></div>
          </div>
        </>
      )}
      {children}
    </>
  );
}