import React from "react";

import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

interface Props {
  title?: string;
  children?: React.ReactNode;
}

export const Accordian = ({ title, children }: Props) => (
  <div className="flex flex-col py-6 border-b-1 border-staff-320">
    <div className="w-full ">
      <div className="w-full max-w-md mx-auto bg-white dark:bg-staff-300 rounded-2xl ">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full rounded-lg ">
                <span className="px-3 text-xl font-bold font-Gilroy text-staff-300 dark:text-white">
                  {title}
                </span>

                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-8 w-14 text-staff-300  dark:text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="pt-4 pb-2 dark:bg-staff-300 text-md text-staff-300">
                {children}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  </div>
);
