import { useState } from "react";

import { Link } from "@remix-run/react";

import useTimeAgo from "~/modules/queries/hooks/useJobTime";

import { ReadMore } from "../ReadMore";

interface Props {
  title?: string;
  jobType?: string;
  location?: string;
  time?: string;
  salary?: string;
  description?: string;
  showBtn?: boolean;
  hidden?: boolean;
  JobData?: any;
  createdDate: string;
  clientJobDescriptionName: string;
  locationName: string;
  categoryName: string;
  jobUrl: string;
  jobDescription: string;
  shortJobDescription: string;
  id: number;
  shareUrl?: string;
  clientJobDescriptionId: number;
  webAdvertSubject: string;
}

export const JobCard = ({
  jobUrl,
  hidden,
  JobData,
  shareUrl,
  ...props
}: Props) => {
  const timeAgo = useTimeAgo(props.createdDate);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleShareClick = () => {
    handleCardClick();
    const copyUrl = `${shareUrl}/jobs/${encodeURIComponent(
      formatText(props.clientJobDescriptionName)
    )}?id=${props.clientJobDescriptionId}`;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(copyUrl)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 10000);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      console.warn("Clipboard not supported");
    }
  };

  const handleCardClick = () => {
    setIsExpanded((prev) => !prev);
  };
  const formatText = (text = '') => text
    .trim()
    .toLowerCase()
    .replace(/[!@#$%^&*(),-/]/g, "") // Remove special characters
    .replace(/\s+/g, "-");

  return (
    <div className="py-3">
      <div
        className={` ${hidden ? "min-w-[288px] min-h-[202px]  md:min-w-full" : ""
          } flex flex-col justify-between h-full  border rounded-md border-staff-420   dark:bg-white  p-5 `}
        onClick={handleCardClick}
      >
        <div>
          <div className="flex justify-between cursor-pointer underline">
            <Link
              rel="canonical"
              to={`/jobs/${encodeURIComponent(props.clientJobDescriptionName && formatText(props.clientJobDescriptionName))}?id=${props.clientJobDescriptionId}`}
              className="cursor-pointer text-base font-semibold text-staff-300 font-SourceSans"
            >
              {props.categoryName +
                (props.webAdvertSubject?.length > 1 ? ` - ${props.webAdvertSubject}` : "")}
            </Link>
            <span className="text-sm font-normal text-staff-170 whitespace-nowrap font-SourceSans">
              {/* {JobData.salary}/hour */}
            </span>
          </div>
          <div className="flex justify-start my-4 space-x-3">
            <div className="flex space-x-2 place-content-start ">
              <svg
                width={13}
                height={15}
                viewBox="0 0 13 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.17399 14.832C6.19538 14.8444 6.2122 14.854 6.22411 14.8607L6.24389 14.8717C6.40175 14.9584 6.59758 14.9577 6.7556 14.872L6.77589 14.8607C6.7878 14.854 6.80462 14.8444 6.82601 14.832C6.8688 14.8072 6.92994 14.771 7.00681 14.7237C7.1605 14.6291 7.37745 14.4895 7.6366 14.3065C8.15402 13.9409 8.84441 13.3986 9.5363 12.6902C10.9134 11.2803 12.3438 9.16186 12.3438 6.4375C12.3438 3.21009 9.72741 0.59375 6.5 0.59375C3.27259 0.59375 0.65625 3.21009 0.65625 6.4375C0.65625 9.16186 2.0866 11.2803 3.4637 12.6902C4.1556 13.3986 4.84598 13.9409 5.3634 14.3065C5.62255 14.4895 5.8395 14.6291 5.99319 14.7237C6.07006 14.771 6.1312 14.8072 6.17399 14.832ZM6.5 8.5625C7.6736 8.5625 8.625 7.6111 8.625 6.4375C8.625 5.2639 7.6736 4.3125 6.5 4.3125C5.3264 4.3125 4.375 5.2639 4.375 6.4375C4.375 7.6111 5.3264 8.5625 6.5 8.5625Z"
                  fill="#031A38"
                />
              </svg>
              <span className="text-sm font-normal text-staff-420 font-Gilroy">
                {props.locationName}
              </span>
            </div>
            <div className="flex space-x-2 place-content-start ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 24 24"
              >
                <path
                  d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"
                  fill="#031A38"
                ></path>
              </svg>
              <span className="text-sm font-normal text-staff-420 font-Gilroy">
                {props.categoryName}
              </span>
            </div>
          </div>
          <div>
            <span
              className={` ${hidden ? "hidden" : "null"
                } text-staff-300   text-base  font-normal font-SourceSans `}
            >
              {props.jobDescription && (
                <ReadMore
                  id="read-more-text"
                  shortText={props.shortJobDescription}
                  longText={props.jobDescription}
                  isExpanded={isExpanded}
                />
              )}
            </span>
          </div>
        </div>

        <div className="flex-col justify-between mt-4 ">
          <span
            className={` ${hidden ? "hidden" : "null"
              } text-staff-170   text-sm whitespace-nowrap  font-SourceSans font-normal flex mb-4`}
          >
            {timeAgo}
          </span>
          <div className="flex gap-5">
            <a
              href={jobUrl}
              className={`${hidden ? "w-full text-center mt-5" : "w-max "
                } font-normal text-white font-SourceSans  bg-staff-400 hover:bg-staff-420 rounded-3xl px-5 py-2 block`}
            >
              Apply for this job
            </a>
            <button
              onClick={handleShareClick}
              className={`${hidden ? "w-full text-center mt-5" : "w-max "
                } font-normal text-white font-SourceSans bg-staff-400 hover:bg-staff-420 rounded-3xl px-5 py-2 block`}
            >
              Share
            </button>

            {isCopied && (
              <span className="text-green-500 text-sm mt-2">
                Copied to clipboard!
              </span>
            )}
          </div>

          <></>
        </div>
      </div>
    </div>
  );
};
