import React from "react";
import Select from "react-select";
import { useState } from "react";

interface Props {
  selectedValue: string;
  setState?: (value: string) => void;
  heading?: string;
  data?: Array<{ value: string }>;
}



export function Input({ heading, data, selectedValue, setState }: Props) {
  const [placeHolder, setPlaceHolder] = useState(selectedValue);
  return (
    <>
      {heading && (
        <label
          id="aria-label"
          htmlFor="aria-select-input"
          className="px-4 text-xl font-bold"
        >
          {heading}
        </label>
      )}

      <Select
        ariaLiveMessages={{
          guidance: () => "", // Disable guidance message
          onChange: () => "", // Disable onChange message
          onFilter: () => "", // Disable onFilter message
          onFocus: () => "", // Disable onFocus message
        }}
        className="py-0 rounded-3xl  text-base font-normal text-left bg-white cursor-pointer font-SourceSans  dark:bg-staff-300"
        aria-labelledby="aria-label"
        placeholder={placeHolder}
        inputId="aria-select-input"
        name="aria-live-color"
        options={data?.map((item) => ({
          value: item.value,
          label: item.value,
        }))}
        menuPosition={"fixed"}
        onChange={(e: any) => {
          setState && setState(e.value);
          setPlaceHolder(e.value);
        }}
        value={{ value: selectedValue, label: selectedValue }} // Ensure value is provided as an object with both value and label properties
      />
    </>
  );
}
