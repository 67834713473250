import { useEffect, useRef, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/20/solid';
import { json } from '@remix-run/node';
import type { LoaderFunction } from '@remix-run/node';
import {useLoaderData } from '@remix-run/react';
import * as turf from '@turf/turf';
import debounce from 'lodash.debounce';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { ClientOnly } from 'remix-utils';

import { LoaderComman } from '~/components/loader/loader';
import { Accordian } from '~/components/organisms/Accordian';
import { AutoLocation } from '~/components/organisms/AutoLocation/AutoLocation.client';
import { Input } from '~/components/organisms/input/Input';
import { JobCard } from '~/components/organisms/JobCard';
import { Slider } from '~/components/organisms/slider/Slider';
import { DummyComponentFallBack, getCategories } from '~/components/search';
import { setFilteredJobsToStore, setJobsToStore, setPageNumberToStore, setPrevScrollPosToStore, setTotalRecordsToStore } from '~/redux/reducers/jobs/job.slice';
import type { Job } from '~/types';

type CacheData = { location: string; lat: number; lng: number; };

export type LoaderData = {
    queryTitle: string ;
    queryLocation: string ;
    queryRadius: number ;
    queryIndustry: string ;
    queryType: string ;
    jobs: Job[];
    GOOGLE_API_KEY: any;
    baseUrl?: string;
    lat: string ;
    lng: string ;
};

export const loader: LoaderFunction = async ({ request }) => {
    const url = new URL(request.url);
    const search = new URLSearchParams(url.search);
    const queryTitle = search.get('jobTitle') || '';
    const queryLocation = search.get('location')  || '';
    const queryRadius = Number(search.get('radius')) || 15 ;
    const queryIndustry = search.get('industry')  || '';
    const queryType = search.get('jobType')  || '';
    const lat = search.get('lat') || '';
    const lng = search.get('lng') || '';
    const baseUrl = process.env.API_BASE_URL;

    return json<LoaderData>({
        queryTitle: queryTitle|| '' ,
        queryRadius: queryRadius || 15,
        queryLocation: queryLocation|| '',
        queryIndustry: queryIndustry|| '',
        queryType: queryType || '',
        lat,
        lng,
        jobs: [],
        GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
        baseUrl
    });
};


export const getJobs = async (pageNumber = 1, pageSize = 1000, jobTitle = "", radius = 15, industry = "", type = "", latitude = "", longitude = "") => {
    const realJobsAPI = "https://webapi.theqbis.com/api/ClientLiveJob/GetClientLiveJobs";
    const environment =
        process.env.NODE_ENV === "development"
            ? realJobsAPI
            : realJobsAPI;

    const body: any = {
        pageNumber,
        pageSize,
        radius: Number(radius),
    };

    if (latitude) {
        body.latitude = latitude;
    }
    if (longitude) {
        body.longitude = longitude;
    }
    if (industry) {
        body.industry = industry;
    }
    if (type) {
        body.type = type;
    }
    if (jobTitle) {
        body.jobTitle = jobTitle;
    }
    
    return fetch(environment, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    }).then((res) => res.json());
};


export default function JobsIndex() {
    const reduxData = useSelector((state: any) => state.job);

    const { queryTitle, queryLocation, queryRadius, queryIndustry, queryType, GOOGLE_API_KEY, baseUrl, lat, lng } = useLoaderData<LoaderData>();
    const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
    const [jobs, setJobs] = useState<Job[]>([]);
    const [title, setTitle] = useState(queryTitle);
    const [location, setLocation] = useState<any>(queryLocation ? { value: { description: queryLocation } } : null);
    const [latitude, setLatitude]= useState(lat ? lat : '');
    const [longitude, setLongitude] = useState(lng ? lng : '');
    const [radius, setRadius] = useState(queryRadius);
    const [industry, setIndustry] = useState(queryIndustry);
    const [jobType, setJobType] = useState(queryType || '');
    const [loading, setLoading] = useState(jobs.length ? false : true);
    const [prevScrollPos, setPrevScrollPos] = useState(reduxData.prevScrollPos);
    const pageSize = 10;
    const [pageNumber, setPageNumber] = useState(queryTitle || queryLocation ? 1 : reduxData.pageNumber);
    const [isChanged, setChanged] = useState(false);
    const [categories, setCategories] = useState(reduxData.categoryList);
    const [totalRecords, setTotalRecords] = useState(reduxData.totalRecords);
    const dispatch = useDispatch();

    const scrollDivRef = useRef<any>(null);

    const fetchData = async (isStoreData = true) => {
        try {
            setLoading(true);
            const { data, totalRecord } = await getJobs(pageNumber, pageSize, title, radius, industry, jobType, latitude ,longitude);
            if (data?.length) {
                const jobsData = isStoreData ? [...jobs, ...data] : data;

                setJobs(jobsData);
                setTotalRecords(totalRecord);

                if(isStoreData) {
                    dispatch(setJobsToStore(jobsData));
                    dispatch(setTotalRecordsToStore(totalRecord));
                }
                
                if (!categories?.length) {
                    const { data } = await getCategories();
                    setCategories(data);
                }

                setFilteredJobs(jobsData);
                dispatch(setFilteredJobsToStore(jobsData));
        
                if (scrollDivRef.current) {
                    scrollDivRef.current.scrollTo(0, prevScrollPos); // Adjust the x and y values as needed
                }
        
                setLoading(false);
                return;
            }

            setJobs([]);
            setTotalRecords(0);
            dispatch(setJobsToStore([]));
            dispatch(setTotalRecordsToStore(0));

            setFilteredJobs([]);
            dispatch(setFilteredJobsToStore([]));
        
            if (scrollDivRef.current) {
                scrollDivRef.current.scrollTo(0, prevScrollPos);
            }
            setLoading(false);
            setChanged(false);
        }
        catch (err: any) {
            setLoading(false);
            setChanged(false);
        }
    };

    useEffect(() => {
        const handleIframeRemoval = () => {
            const iframes = document.getElementsByTagName('iframe');
            for (let i = 0; i < iframes.length; i++) {
                const iframe = iframes[i];
                iframe.remove();
            }
        };

        handleIframeRemoval();

        const interval = setInterval(() => {
            handleIframeRemoval();
        }, 1000);

        const updateHeight = () => {
            document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight - 321}px`);
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);
        if (!filteredJobs.length) {
            fetchData();
        }

        return () => {
            window.removeEventListener('resize', updateHeight);
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const handleScroll = debounce((e: any) => {
            const scrollContainer = e.target;
            const currentScrollPos = e.target.scrollTop;
            const maxScrollPos = scrollContainer.scrollHeight - scrollContainer.clientHeight;

            if ((currentScrollPos > reduxData.prevScrollPos) && (currentScrollPos >= (maxScrollPos - 1))) {
                setChanged(true);
                setPrevScrollPos(currentScrollPos);
            }
            dispatch(setPrevScrollPosToStore(currentScrollPos));
        }, 100);

        const scrollContainer = document.querySelector('.card-height');
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [prevScrollPos]);

    useEffect(() => {
        if (prevScrollPos && isChanged) {
            setPageNumber(pageNumber + 1);
            dispatch(setPageNumberToStore(pageNumber + 1));
        }
    }, [prevScrollPos]);

    useEffect(() => {
        if(isChanged) {
            fetchData(false);
        }
    }, [title, location, radius, industry, jobType]);

    useEffect(() => {
        if (isChanged && (totalRecords !== jobs.length)) {
            fetchData();
        }
    }, [pageNumber]);

    const setChangePageNum = () => {
        setChanged(true);
        setPageNumber(1);
        setPrevScrollPos(0);
    }

    const handleClearJob = () => {
        setTitle('');
        setChangePageNum();
    };
    const handleClearLocation = () => {
        setLocation(null);
        setLatitude("");
        setLongitude("");
        setRadius(15);
        setChangePageNum();
    };

    const handleClearRadius = () => {
        setRadius(15);
        setChangePageNum();
    };

    const handleClearIndustry = () => {
        setIndustry('');
        setChangePageNum();
    };

    const handleClearType = () => {
        setJobType('');
        setChangePageNum();
    };
    const clearAll = async () => {
        handleClearJob();
        handleClearLocation();
        handleClearRadius();
        handleClearType();
        handleClearIndustry();
    };

    return (
        <LoaderComman isLoading={loading}>
            <div className="mx-auto max-w-7xl">
                <div className="grid-cols-6 px-6 py-8 md:grid">
                    <span className="mt-6 mb-3 ml-1 text-5xl font-bold whitespace-nowrap text-staff-300 dark:text-white">
                        {totalRecords == 0
                            ? 'No jobs'
                            : totalRecords == 1
                                ? '1 Vacancy'
                                : `${totalRecords} Vacancies`}
                    </span>
                </div>
                <div className="grid-cols-6 px-6 py-8 md:grid">
                    <div className="col-span-2">
                        <div className="pr-6">
                            {(title !== 'Job Title' || location != null) && (
                                <div className="pt-3 pb-6 ">
                                    <button
                                        onClick={clearAll}
                                        className="px-2 mt-8 text-sm font-semibold text-staff-300 hover:bg-staff-400 dark:text-white">
                                        Clear All
                                    </button>
                                </div>
                            )}

                            <div className="p-2 mt-3 mb-8 border-t-1 border-staff-320">
                                <Accordian title="Job Title">
                                    <div className="px-4">
                                        {title !== '' ? (
                                            <div className="flex items-center justify-between p-2 space-x-2 transition-all duration-75 ease-in rounded-md max-w-max mb-7 border-1 border-staff-320">
                                                <span className="text-sm font-semibold whitespace-nowrap text-staff-300 dark:text-white">
                                                    {title}
                                                </span>
                                                <button className="w-6 h-6 dark:text-white" onClick={handleClearJob}>
                                                    <XMarkIcon />
                                                </button>
                                            </div>
                                        ) : null}
                                        <Input
                                            data={categories
                                                ?.map((v: Job) => v.categoryName)
                                                ?.filter((v: string, i: number, a: any) => a.indexOf(v) === i)
                                                ?.map((jobTitle: string) => ({ value: jobTitle }))}
                                            setState={(e: any) =>{
                                                setTitle(e);
                                                setChangePageNum();
                                            }}
                                            selectedValue={title}
                                        />
                                    </div>
                                </Accordian>
                                <Accordian title="Location">
                                    <div className="px-4">
                                        <span className="text-sm font-bold text-black ">
                                            <div className="flex flex-wrap">
                                                {location?.value.description === undefined ? (
                                                    ''
                                                ) : (
                                                    <div className="flex items-center justify-between p-2 m-1 space-x-2 transition-all duration-75 ease-in rounded-md max-w-max border-1 border-staff-320">
                                                        <span className="text-sm font-semibold whitespace-nowrap text-staff-300 dark:text-white">
                                                            {location?.value.description}
                                                        </span>
                                                        <button
                                                            className="w-6 h-6 dark:text-white"
                                                            onClick={handleClearLocation}>
                                                            <XMarkIcon />
                                                        </button>
                                                    </div>
                                                )}

                                                {location?.value.description != undefined && radius != 0 && (
                                                    <div className="flex items-center justify-between p-2 m-1 space-x-2 transition-all duration-75 ease-in rounded-md max-w-max border-1 border-staff-320">
                                                        <span className="text-sm font-semibold whitespace-nowrap text-staff-300 dark:text-white">
                                                            {radius} miles
                                                        </span>
                                                        <button
                                                            className="w-6 h-6 dark:text-white"
                                                            onClick={handleClearRadius}>
                                                            <XMarkIcon />
                                                        </button>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="w-full mt-5 mb-1 ">
                                                <ClientOnly fallback={<DummyComponentFallBack />}>
                                                    {() => (
                                                        <AutoLocation apiKey={GOOGLE_API_KEY} setLocation={async (e: any) => {
                                                            const [centerRes] = await geocodeByAddress(e.value.description);
                                                            const centerPos = await getLatLng(centerRes);
                                                            setLatitude(centerPos.lat.toString());
                                                            setLongitude(centerPos.lng.toString());
                                                            setLocation(e);
                                                            setChangePageNum();
                                                        }} />
                                                    )}
                                                </ClientOnly>
                                            </div>
                                        </span>

                                        {location?.value.description == undefined ? (
                                            ""
                                        ) : (
                                            <div
                                                data-testid="SAUK-mobile-search-modal-radius-slider"
                                                className="my-3 "
                                            >
                                                <Slider
                                                    label="Radius"
                                                    state={(e: any) => {
                                                        setRadius(e);
                                                        setChangePageNum();
                                                    }}
                                                    radius={radius}
                                                    isMobile={false}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Accordian>
                                <Accordian title="Industry">
                                    <div className="px-4">
                                        {industry !== "" ? (
                                            <div className="flex items-center justify-between p-2 space-x-2 transition-all duration-75 ease-in rounded-md max-w-max mb-7 border-1 border-staff-320">
                                                <span className="text-sm font-semibold whitespace-nowrap text-staff-300 dark:text-white">
                                                    {industry}
                                                </span>
                                                <button
                                                    className="w-6 h-6 dark:text-white"
                                                    onClick={handleClearIndustry}
                                                >
                                                    <XMarkIcon />
                                                </button>
                                            </div>
                                        ) : null}
                                        <Input
                                            data={[
                                                { value: "Industrial" },
                                                { value: "Driving" },
                                            ]}
                                            setState={(e: any) => {
                                                setIndustry(e);
                                                setChangePageNum();
                                            }}
                                            selectedValue={industry}
                                        />
                                    </div>
                                </Accordian>

                                <Accordian title="Type">
                                    <div className="px-4">
                                        {jobType !== "" ? (
                                            <div className="flex items-center justify-between p-2 space-x-2 transition-all duration-75 ease-in rounded-md max-w-max mb-7 border-1 border-staff-320">
                                                <span className="text-sm font-semibold whitespace-nowrap text-staff-300 dark:text-white">
                                                    {jobType}
                                                </span>
                                                <button
                                                    className="w-6 h-6 dark:text-white"
                                                    onClick={handleClearType}
                                                >
                                                    <XMarkIcon />
                                                </button>
                                            </div>
                                        ) : null}
                                        <Input
                                            data={[
                                                { value: "Temp" },
                                                { value: "Temp to perm" },
                                                { value: "Ad-hoc" },
                                            ]}
                                            setState={(e: any) => {
                                                setJobType(e);
                                                setChangePageNum();
                                            }}
                                            selectedValue={jobType}
                                        />
                                    </div>
                                </Accordian>
                            </div>
                        </div>
                    </div>
                    <div className="w-full col-span-4 ">
                        <div className="w-lg bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden ps-3 mt-0">
                            <div ref={scrollDivRef} className='card-height' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                                <div className={`flex justify-center dark:text-white ${loading || !filteredJobs.length ? 'p-4' : 'd-none'}`}>
                                    {loading ? "Loading..." : !filteredJobs.length && "There are no matching jobs."}
                                </div>
                                {filteredJobs?.map((job: any, index) =>
                                    loading ? (
                                        <JobCardSkeleton key={index} />
                                    ) : (
                                        <JobCard
                                            key={index}
                                            jobid={job.id}
                                            jobUrl={job.jobUrl}
                                            {...job}
                                            shareUrl={baseUrl}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoaderComman>
    );
}

const JobCardSkeleton: React.FC = () => (
    <div className="py-2">
        <div className="flex flex-col p-5 border rounded-md animate-pulse border-staff-320 dark:bg-white ">
            <div className="w-1/6 h-2 rounded bg-slate-200 dark:bg-slate-700"></div>
            <div className="flex justify-start w-1/4 my-4 space-x-3">
                <div className="w-1/3 h-2 rounded bg-slate-200 dark:bg-slate-700"></div>
                <div className="w-1/3 h-2 rounded bg-slate-200 dark:bg-slate-700"></div>
            </div>
            <div className="rounded h-14 bg-slate-200 dark:bg-slate-700"></div>
            <div className="flex items-center justify-between mt-4">
                <div className="w-1/12 h-2 rounded bg-slate-200 dark:bg-slate-700"></div>
                <div className="w-1/5 h-10 bg-slate-200 dark:bg-slate-700 rounded-3xl"></div>
            </div>
        </div>
    </div>
);

