import { useState } from 'react';
import parse from 'html-react-parser';

interface ReadMoreProps {
    id: string;
    shortText: string;
    longText: string;
    isExpanded: boolean;
}

export const ReadMore = ({ id, shortText, longText, isExpanded }: ReadMoreProps) => {
    return (
        <p id={id}>
            {!isExpanded && shortText}
            {!isExpanded && <span>... </span>}
            {isExpanded && <span>{parse(longText)} </span>}
            <span className="text-staff-420" role="button" tabIndex={0} aria-expanded={isExpanded} aria-controls={id}>
                {isExpanded ? 'show less' : 'show more'}
            </span>
        </p>
    );
};